define("gm-ui/templates/application", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "B6FM6Go9",
    "block": "[[[1,[28,[35,0],[\"Gmail Meeting\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"min-h-screen min-w-full bg-white\"],[12],[1,\"\\n  \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[1,[28,[35,3],null,[[\"position\",\"zindex\"],[\"top-right\",\"9999\"]]]]],[],false,[\"page-title\",\"component\",\"-outlet\",\"notification-container\"]]",
    "moduleName": "gm-ui/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});