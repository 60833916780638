define("gm-ui/routes/index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class IndexRoute extends _route.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", {
        token: {
          replace: null
        }
      });
    }

    // console.log('asdas');
    model(params) {
      try {
        var decodedString = atob(params.token);
        console.info('STRING:', decodedString);
        var decodedData = JSON.parse(decodedString);
        decodedData.timeSlots.forEach(e => {
          e.date = Date.parse(e.date); // e.slots.forEach(s => {
          //   e.endtTime = Date.parse(s.endtTime);
          //   e.startTime = Date.parse(s.startTime);
          // });
        });
      } catch (e) {
        //alert(e); // error in the above string (in this case, yes)!
        decodedData = null;
        console.error('Could not parse JSON');
      } // console.info('TESTING:', decodedData);


      this.set('tokenObj', decodedData);
    }

    setupController(controller, model) {
      super.setupController(...arguments);
      controller.set('tokenObj', this.tokenObj);
    }

  }

  _exports.default = IndexRoute;
});